import localStorageHelper, { LocalStorageKeyType } from '@/Utils/localStorageHelper';
import resourceHelper from '@/Utils/resourceHelper';
import tokenManager from '@/Utils/tokenManager';
import uploadManager from '@/Utils/uploadManager';
import webBrowserDetector from '@/Utils/webBrowserDetector';

/**
 * Module to assist with redirects and handling displaying a confirmation prompt when a user
 * attempts to navigate away or close the browser window while uploads are in progress.
 */
function RedirectHelper() {

  var self = this;

  /** Flags whether the navigate away with uploads prompt is displayed or not */
  var bypassNavigateAwayPrompt = false;

  window.onbeforeunload = function () {

    if (!bypassNavigateAwayPrompt && uploadManager.uploads().length) {
      return resourceHelper.getString('NavigateAwayWithUploadsMessage');
    }

    return undefined; // Returning undefined when no prompt required for IE compatibility
  };

  self.redirectToPath = relativePath => {
    if (!isInternetExplorer()) {
      window.location.href = relativePath;
    }
  };

  /**
   * @param {string} hash Hash to route to (including the # character)
   */
  self.redirectToHash = hash => {
    if (!isInternetExplorer()) {
      window.location.hash = hash;
    }
  };

  /**
   * Opens help for the page specified with the help Id. The help page is loaded in a new tab or window
   * depending on the browser settings.
   * @param {string} helpId
   */
  self.openHelpWindow = helpId => {
    if (!isInternetExplorer()) {
      const helpUrl = '/Content/Help/#' + helpId;
      window.open(helpUrl, '_blank');
    }
  };

  self.tokenLoginRedirect = () => {
    if (!isInternetExplorer()) {

      // Before we redirect the user to authenticate, save the hash route the user attempted to access so
      // that once the user is then authenticated, we can load the page they originally attempted to access.

      self.saveRouteHash();
      tokenManager.loginRedirect();
    }
  };

  self.saveRouteHash = () => {
    const hash = window.location.hash;

    if (hash) {
      localStorageHelper.setSessionValue(LocalStorageKeyType.AuthenticationRedirectHash, hash);
    }
  };

  /**
   * @param {string} registrationId
   */
  self.tokenRegistrationRedirect = registrationId => {
    if (!isInternetExplorer()) {
      // Before we redirect a user to authenticate / register their authentication details, save the
      // registration Id so once authenticated they can continue with their registration.
      //
      // IDS3 used a list of exact redirect URLs so can't have a query string parameter with the
      // registration Id on for the direct URL hence using a session value.
      localStorageHelper.setSessionValue(LocalStorageKeyType.AuthenticationRegistrationId, registrationId);

      tokenManager.loginRedirect();
    }
  };

  self.loginRedirect = function () {
    if (!isInternetExplorer()) {
      window.location.href = '/';
    }
  };

  self.postPortalAuthenticationRedirect = () => {
    if (!isInternetExplorer()) {
      const routeHash = localStorageHelper.getSessionValue(LocalStorageKeyType.AuthenticationRedirectHash);
      let redirect = '/';

      // If we have saved the hash route the user originally attempted to access before they were redirected
      // to the login page then redirect to the SPA with the hash route after clearing the hash from storage.
      if (routeHash) {
        localStorageHelper.clearSessionValue(LocalStorageKeyType.AuthenticationRedirectHash);
        redirect += routeHash;
      }

      window.location.replace(redirect);
    }
  };

  self.postRegistrationAuthenticationRedirect = () => {
    if (!isInternetExplorer()) {
      let url = '/registration.html?id=';

      const registrationId = localStorageHelper.getSessionValue(LocalStorageKeyType.AuthenticationRegistrationId);

      if (registrationId) {
        url += encodeURIComponent(registrationId);
      } else {
        console.warn('The expected registration Id was not found in local storage');
      }

      // If the registration Id doesn't exist, it will fail to be looked up and an appropriate error message
      // will be displayed on the registration screen.

      window.location.replace(url);
    }
  };

  /**
   * Logs a user out from the site and if there are active uploads, displays a confirmation prompt before
   * the user is redirected to the authentication page.
   */
  self.logoutRedirect = function () {

    if (!uploadManager.uploads().length) {
      tokenManager.logoutRedirect();
      return;
    }

    // If file uploads are in progress then display a native browser confirm prompt to the user informing
    // them of the active uploads and effect of logging out. A native browser prompt is used as it's inline
    // with the native browser alert displayed on the window onbeforeunload event.
    var navigateAwayMessage = resourceHelper.getString('NavigateAwayWithUploadsMessage');
    var logoutPromptQuestion = resourceHelper.getString('LogoutWithUploadsQuestion');
    var message = navigateAwayMessage + '\n\n' + logoutPromptQuestion;

    if (window.confirm(message)) {
      bypassNavigateAwayPrompt = true;
      tokenManager.logoutRedirect();
    }
  };

  self.reload = function () {
    location.reload();
  };

  self.isInternetExplorerInUse = () => {
    return isInternetExplorer();
  };

  // Before we redirect the user check if user doesn't use Internet Explorer
  // If user does, just redirect them to internetExplorerBlocked page
  function isInternetExplorer() {
    if (webBrowserDetector.isIE()) {
      const url = '/internetExplorerBlocked.html';
      window.location.replace(url);
      return true;
    }
    return false;
  }
}

export default new RedirectHelper();

import ko from 'knockout';
import logger from '../Utils/logger';
import config from '../config';
import contextData from '../contextData';
import LanguageDetails from '../Types/languageDetails';

class LanguageHelper {
  /**
   * Gets the newest supported language details based on the "updatedDate" property. If no matches
   * are made, the first supported language is returned.
   * @param {LanguageDetails[]} languageDetailsArray
   * @return {string}
   */
  getNewestLanguageCodeOrFirstSupported = (languageDetailsArray: LanguageDetails[]): string => {
    const languageCode = this.getNewestLanguageCode(languageDetailsArray);

    if (!languageCode) {
      const firstSupportedLanguageCode = config.supportedLanguages[0].languageCode;
      console.debug(`Suitable language details were not found so first supported language '${firstSupportedLanguageCode}' is being used`);
      return firstSupportedLanguageCode;
    }

    return languageCode;
  }

  /**
   * Gets the newest supported language details based on the "updatedDate" property. If no matches
   * are made, null is returned.
   * @param {LanguageDetails[]} languageDetailsArray
   * @return {string|null}
   */
  getNewestLanguageCode = (languageDetailsArray: LanguageDetails[]): string | null => {
    const parsedLanguageDetailsArray = languageDetailsArray.map(x => this.parseLanguageDetails(x));
    const newestLanguageDetails = parsedLanguageDetailsArray
      .filter(item => item !== null)
      .sort((a, b) => b.updatedDate.getTime() - a.updatedDate.getTime()) // Sort epoch highest to lowest
      .find(item => item); // Get first not falsey

    if (newestLanguageDetails) {
      const debugMessage = `Newest language details has code '${newestLanguageDetails.languageCode}' and is from '${newestLanguageDetails.source}'`;
      console.debug(debugMessage, newestLanguageDetails);

      return newestLanguageDetails.languageCode;
    }

    return null;
  }

  /**
   * Takes an input language code and parses it into a language code that the Business Portal
   * supports. If the input language code contains a region (e.g. 'en-ES') but that specific
   * language code is not supported, an attempt for a match will be made on just the language
   * (e.g. 'en'). If there is no match, null is returned.
   * @param {LanguageDetails} languageDetails
   * @return {LanguageDetails|null}
   */
  private parseLanguageDetails = (languageDetails: LanguageDetails): LanguageDetails | null => {
    if (!languageDetails.languageCode) {
      return null;
    }

    let supportedLanguage = config.supportedLanguages
      .find(x => x.languageCode.toLowerCase() == languageDetails.languageCode.toLowerCase());

    // Perform a partial match e.g. ignore the regional part if it exists
    if (!supportedLanguage) {
      const parts = languageDetails.languageCode.split('-');
      supportedLanguage = config.supportedLanguages
        .find(x => x.languageCode.toLowerCase().startsWith(parts[0].toLowerCase()));
    }

    if (supportedLanguage) {
      return {
        languageCode: supportedLanguage.languageCode,
        updatedDate: languageDetails.updatedDate,
        source: languageDetails.source
      };
    }

    return null;
  }

  /**
   * Gets current selected language resourceKey
   * @return {string}
   */
  getCurrentLanguageResourceKey = () => {
    if (!contextData.languageCode()) {
      return '';
    }

    const languages = this.getSupportedLanguages();

    const foundLanguage = ko.utils.arrayFirst(languages,
      function (item) {
        return item.languageCode === contextData.languageCode();
      });

    if (!foundLanguage) {
      logger.error('LanguageIsNotSupported', null, contextData.languageCode);

      return '';
    }

    return foundLanguage.resourceKey;
  };

  /**
   * Gets languages list. Filters out TestLanguage if in ReleaseMode
   * @return {array}
   */
  getSupportedLanguages = () => {

    const languages = config.supportedLanguages;

    return contextData.inReleaseMode ?
      languages.filter(item => item.resourceKey !== 'LanguageNameTestLanguage') :
      languages;
  };

  getAvailableLanguages = (availableLanguageCodes: string[]) => {
    // If the list of available languages is empty then the list is not being controlled via the
    // system setting "AvailableLanguageCodes" so allow access to all supported languages.
    if (!availableLanguageCodes?.length) {
      return this.getSupportedLanguages();
    }

    return config.supportedLanguages.filter(available => availableLanguageCodes.includes(available.languageCode));
  }
}

export default new LanguageHelper();

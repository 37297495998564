class FileSizeFormatter {
  public static getFormattedFileSize = (bytes: number): string => {

    if (bytes === 1) {
      return '1 Byte';
    }

    if (bytes < 1024) {
      return bytes + ' Bytes';
    }

    if (bytes < 1048576) {
      return FileSizeFormatter.roundDownToOneDp((bytes / 1024)) + ' KB';
    }

    if (bytes < 1073741824) {
      return FileSizeFormatter.roundDownToOneDp((bytes / 1048576)) + ' MB';
    }

    return FileSizeFormatter.roundDownToOneDp((bytes / 1073741824)) + ' GB';
  }

  private static roundDownToOneDp = (num: number): string => {
    num = num * 10;
    num = Math.floor(num);
    num = num / 10;

    return num.toFixed(1);
  }

}
export default FileSizeFormatter;

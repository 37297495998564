class QuerystringHelper {

  /**
   * Get the value of a querystring
   * @param {string} name The query string name for the value to return
   * @return {string|null}
   */
  static getQueryString = (name: string): string | null => {
    const href = window.location.href;
    const regExp = new RegExp('[?&]' + name + '=([^&#]*)', 'i');
    const string = regExp.exec(href);
    return string ? string[1] : null;
  };
}

export default QuerystringHelper;

import ko from 'knockout';
import crossroads from 'crossroads';
import hasher from 'hasher';
import routes from '../routes';
import logger from '../Utils/logger';
import resourceHelper from '../Utils/resourceHelper';

/**
 * The router for the SPA that reacts to URL hash changes and causes a new page component load
 * @param {Object} routes
 */
function Router(routes) {

  var self = this;

  // When the SPA initally loads, if there are "resolve" promises for the route, set the intial page to
  // "loading-data" to display an animation to the user until the promises are resolved.
  self.currentRoute = ko.observable({ page: 'loading-data' });

  // The page title on the <title> element under the <head> element
  self.pageTitle = ko.observable();

  self.disablePageComponent = ko.observable(false);

  self.init = function () {

    ko.utils.arrayForEach(routes, function (route) {
      crossroads.addRoute(route.url, function (requestParams) {
        handleRouteChange(route, requestParams);
      });
    });

    crossroads.normalizeFn = crossroads.NORM_AS_OBJECT;

    // Execute an action every time crossroads can't match any route
    crossroads.bypassed.add(function (hash) {
      logger.error('UnhandledError', 'There is no route configured for the route hash \'#' + hash + '\'.');
    });

    hasher.initialized.add(parseHash);
    hasher.changed.add(parseHash);
    hasher.init();
  };

  function handleRouteChange(route, requestParams) {

    var previousRoute = {
      page: self.currentRoute().page,
      id: self.currentRoute().id || null
    };

    var ariaLiveNavigationText = resourceHelper.getString('NavigatingToPage') + ' ' +
      resourceHelper.getString(route.params.pageTitleKey);

    var viewModelParams = ko.utils.extend(requestParams, route.params);
    viewModelParams = ko.utils.extend(viewModelParams, {
      previousRoute: previousRoute,
      ariaLiveNavigationText: ariaLiveNavigationText
    });

    var promisesToResolve = route.resolve;

    if (!promisesToResolve) {
      self.currentRoute(viewModelParams);
      changePageTitle(requestParams.pageTitleKey);
      return;
    }

    self.disablePageComponent(true);

    getResolvedData(route.resolve, viewModelParams)
      .then(function (resolvedData) {

        viewModelParams = ko.utils.extend(viewModelParams, resolvedData);
        self.currentRoute(viewModelParams);
        changePageTitle(requestParams.pageTitleKey);
        self.disablePageComponent(false);

      })
      .catch(function (errorObject) {

        self.disablePageComponent(false);

        if (!errorObject.errorHasBeenLogged) {
          self.currentRoute({ page: 'page-not-available' });
        }
      });
  }

  /**
   * For each resolve property, execute the functions to return the promises and once each promise is
   * resolved, return an object with each of the resolve property names and the resolved promise values.
   * @param {*} resolve
   * @param {*} params
   * @return {*}
   */
  function getResolvedData(resolve, params) {

    var promises = [];
    var propertyNames = [];
    var promise;

    ko.utils.objectForEach(resolve, function (property) {
      promise = resolve[property](params);
      promises.push(promise);
      propertyNames.push(property);
    });

    return new Promise(function (resolve, reject) {

      Promise.all(promises)
        .then(function (resolvedItems) {

          var i;
          var resolvedData = {};

          for (i = 0; i <= promises.length; i++) {
            resolvedData[propertyNames[i]] = resolvedItems[i];
          }

          resolve(resolvedData);

        })
        .catch(function (e) {
          reject(e);
        });
    });
  }

  /**
   * Change the title on the <title> element under the <head> element
   * @param {string} pageTitleKey The key to use to lookup the translated text
   */
  function changePageTitle(pageTitleKey) {

    var title = resourceHelper.getString('PortalSpaWebpageTitle');

    if (pageTitleKey) {
      title = resourceHelper.getString(pageTitleKey) + ' | ' + title;
    }

    self.pageTitle(title);
  }

  function parseHash(newHash) {
    crossroads.parse(newHash);
  }
}

export default new Router(routes);

import moment from 'moment';
import tokenManager from '@/Utils/tokenManager';

// Checks token metadata, runs events on token expiration
class TokenExpTimer {

  // Initiates auth token expiration self-perpetual timer.
  // This init is to be called on portal startup.
  public initAuthTokenExpirationTimer = expirationCallback => {
    this.setAuthTokenExpirationTimer(expirationCallback);
  };

  // Checks auth token metadata, runs token expiration callbacks when there're due.
  // Self-perpetual within page lifetime, will re-invoke itself until expiration callback is reached.
  private setAuthTokenExpirationTimer(expirationCallback) {
    // Get auth token expiry:
    const authTokenExp = this.readAuthTokenExpiresUtc();
    if (!authTokenExp) {
      // Token metadata is always expected to be found,
      // but if for some reason it's not there, just don't do anything.
      console.log('Auth token expiry is not found. Auth token expiration timer is not engaged.');
      return;
    }

    // Read expiration and now dates, calculationg difference:
    const nowDate = moment.utc();

    const expiryDate = moment(authTokenExp).utc();
    const timeToExpirationMilliseconds: number = expiryDate.diff(nowDate);

    // console.log('Now date:                                         ' + nowDate.format('HH:mm:ss.SSS'));
    console.log('Token expires at:                                ' + expiryDate.format('HH:mm:ss.SSS'));
    // console.log('Seconds till expiration:                          ' + timeToExpirationMiliseconds / 1000);

    if (timeToExpirationMilliseconds <= 0) {
      // Metadata indicates that expiration warning is already to be fired
      expirationCallback();
      return;
    }

    setTimeout(expirationCallback, timeToExpirationMilliseconds);
  }

  private readAuthTokenExpiresUtc(): number {
    const token = this.parseJwt(tokenManager.getAccessToken());
    return token.exp * 1000;
  }

  private parseJwt(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }
}

export default new TokenExpTimer();

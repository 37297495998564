import ko from 'knockout';
import logger from '../Utils/logger';
import config from '../config';

/**
 * Module to determine how long a user has been inactive on the SPA and to set observables to flag an
 * inactivity warning and user inactive allowing other modules to then react to and instigate actions
 * and / or UI changes.
 */
function InactivityHelper() {

  var self = this;

  /** The Id returned from the window.setTimeout call */
  var timeoutId;

  // ----------------------------------------------------------------------------------------------------
  // Due to the number of events that can fire for the window mouse, key and touch events, we need to
  // ensure the event handler runs as lean as possible. Due to this, use an internal variable as well as
  // a Knockout observable for the idle period. The observable is not evaluated in the event handler,
  // only the variable is.
  // ----------------------------------------------------------------------------------------------------
  var internalIdlePeriodInMinutes = 0;

  self.idlePeriodInMinutes = ko.observable(0);
  self.showInactiveWarning = ko.observable(false);
  self.isInactive = ko.observable(false);

  addEventListeners();
  startTimer();

  function startTimer() {
    timeoutId = window.setTimeout(calculateInactive, 60 * 1000);
  }

  /**
   * Function to be called every minute that has elapsed while the user is idle. Updates the totals and
   * determines if a warning should be displayed and if the user is now deemed inactive.
   */
  function calculateInactive() {

    internalIdlePeriodInMinutes++;
    self.idlePeriodInMinutes(internalIdlePeriodInMinutes);

    logger.debug('Idle for %d minute(s)', internalIdlePeriodInMinutes);

    if (internalIdlePeriodInMinutes === config.periodTillInactiveWarningInMinutes) {
      logger.debug('The idle warning period has now been met');
      self.showInactiveWarning(true);
    }

    if (internalIdlePeriodInMinutes === config.periodTillInactiveInMinutes) {
      setInactive();
    } else {
      startTimer();
    }
  }

  /**
   * Function called as the handler for window mouse, key and touch events and resets totals. Where the
   * events this function handles can fire at very high frequency, code in this function must be efficent.
   */
  function resetTimer() {

    window.clearTimeout(timeoutId);

    if (internalIdlePeriodInMinutes !== 0) {
      setActive();
    }

    startTimer();
  }

  function setInactive() {

    logger.debug('Now deemed inactive');

    self.isInactive(true);

    removeEventListeners();
  }

  function setActive() {

    logger.debug('Now deemed active');

    internalIdlePeriodInMinutes = 0;

    self.idlePeriodInMinutes(0);
    self.showInactiveWarning(false);
    self.isInactive(false);
  }

  function addEventListeners() {
    window.addEventListener('mousemove', resetTimer, false);
    window.addEventListener('mousedown', resetTimer, false);
    window.addEventListener('keypress', resetTimer, false);
    window.addEventListener('touchmove', resetTimer, false);
  }

  function removeEventListeners() {
    window.removeEventListener('mousemove', resetTimer, false);
    window.removeEventListener('mousedown', resetTimer, false);
    window.removeEventListener('keypress', resetTimer, false);
    window.removeEventListener('touchmove', resetTimer, false);
  }
}

export default new InactivityHelper();

import resourceHelper from '@/Utils/resourceHelper';
import constants from '@/constants';
import RequestAssigneeSummaryModel from '@/Models/requestAssigneeSummaryModel';

class RequestAssigneeFormatter {
  static getRequestAssigneeTitle(data: RequestAssigneeSummaryModel): string {
    let title = '';
    if (data.assigneeType === constants.requestAssigneeTypes.user) {
      if (data.isDisabled) {
        title = resourceHelper.getString('UnregisteredUserTitle');
      }
    } else if (data.assigneeType === constants.requestAssigneeTypes.group) {
      if (data.isGroupEmpty) {
        title = resourceHelper.getString('EmptyGroupTitle');
      }
    }

    return title;
  }

  static getRequestAssigneeIconClass(data: RequestAssigneeSummaryModel): string {
    let entityIconClass = '';
    if (data.assigneeType === constants.requestAssigneeTypes.user) {
      if (data.isDisabled) {
        entityIconClass = constants.preRegisteredUserIconClass;
      } else {
        entityIconClass = 'fa-user';
      }
    } else if (data.assigneeType === constants.requestAssigneeTypes.group) {
      if (data.isDisabled) {
        entityIconClass = 'fa-users-rays';
      } else if (data.isGroupEmpty) {
        entityIconClass = 'fa-users-slash';
      } else {
        entityIconClass = 'fa-users';
      }
    }

    return 'fas ' + entityIconClass;
  }
}
export default RequestAssigneeFormatter;

import ko from 'knockout';
import logger from '@/Utils/logger';
import constants from '@/constants';

function UploadManager() {

  var self = this;

  // Observables to hold all the file uploads and the overall upload progress across all files
  // Important: do not use with ko rateLimit (see below).
  // self.uploads = ko.observableArray([]).extend({ rateLimit: 500 });
  // RateLimit breaks invoking of fileupload.send methods
  // Bug 188233: [Business Portal] Uploading of additional files causes error after starting upload of bunch of files
  self.uploads = ko.observableArray([]);
  self.uploadsCount = ko.observable(0);

  self.completedUploadsCount = ko.observable(0);
  self.failedUploadsCount = ko.observable(0);

  self.allUploadsProgress = ko.observable(0);
  self.allUploadsComplete = ko.observable(false);
  self.uploadsFailed = ko.observable(false);

  self.addUpload = function (upload) {
    self.uploads.push(upload);
    self.uploadsCount(self.uploads().length);
    self.allUploadsComplete(false);
  };

  self.getUpload = function (uploadId) {

    var upload = null;

    ko.utils.arrayForEach(self.uploads(), function (currentUpload) {

      if (currentUpload.uploadId === uploadId) {
        upload = currentUpload;
      }
    });

    return upload;
  };

  self.getUploadToken = function (file) {
    var upload = getUpload(file);
    return upload.uploadToken;
  };

  self.setUploadStarted = function (file) {
    var upload = getUpload(file);
    upload.setUploadStarted();
  };

  self.setAllUploadsProgress = function (allUploadsProgress) {
    self.allUploadsProgress(allUploadsProgress);
  };

  self.updateUploadProgress = function (file, progress, bytesUploaded) {

    var upload = getUpload(file);

    // If a file was removed but a chunk finished uploading after the Ajax request was cancelled then
    // don't do anything.
    if (!upload) {
      return;
    }

    upload.updateUploadProgress(progress, bytesUploaded);
  };

  self.setUploadComplete = function (file) {
    var upload = getUpload(file);
    upload.setUploadComplete();
    removeUpload(upload);
    self.completedUploadsCount(self.completedUploadsCount() + 1);
  };

  // TODO: Can the error be passed across here as well?
  self.setUploadFailed = function (file) {

    var upload = getUpload(file);

    // If the upload was cancelled, then we know about this ajax failure and can ignore it. Only if the
    // upload wasn't cancelled display the upload failure message.
    if (upload.statusName() === constants.uploadStatuses.cancelled) {
      return;
    }

    upload.setUploadFailed();
    removeUpload(upload);

    var errorMessage = 'Filename: ' + upload.fileName;
    logger.errorWithoutTimeout('UploadFailure', errorMessage);
    self.failedUploadsCount(self.failedUploadsCount() + 1);

    self.uploadsFailed(true);
  };

  /**
   * Cancels a file that is currently being uploaded or is pending upload
   * @param {*} upload FileUpload object
   */
  self.cancelUpload = function (upload) {
    upload.cancelUpload();
    removeUpload(upload);
  };

  function getUpload(file) {

    var upload = null;

    ko.utils.arrayForEach(self.uploads(), function (currentUpload) {

      if (currentUpload.nativeBrowserFile === file) {
        upload = currentUpload;
      }
    });

    return upload;
  }

  function removeUpload(fileUpload) {

    self.uploads.remove(fileUpload);
    self.uploadsCount(self.uploads().length);

    if (self.uploadsCount() === 0) {
      self.allUploadsProgress(0);
      self.allUploadsComplete(true);
    }
  }
}

export default new UploadManager();

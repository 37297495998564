import toastr from '@publicsafety/toastr';
import resourceHelper from './resourceHelper';
import config from '../config';

function Logger() {

  var self = this;

  toastr.options = {
    closeButton: true,
    progressBar: true,
    newestOnTop: true,
    timeOut: 4000
  };

  /**
   * Displays an error to the user and logs to the console
   * @param {string} resourceKey
   * @param {string|null} [debugMessage] Message to be logged for debugging / internal purposes
   * @param {*} [errorObject]
   */
  self.error = function (resourceKey, debugMessage, errorObject) {

    var message = resourceHelper.getString(resourceKey);
    var errorDetails = createDetails(resourceKey, message, debugMessage, errorObject);

    if (config.showErrorDebugMessage && debugMessage) {
      message += ' ' + debugMessage;
    }

    console.error(errorDetails);
    toastr.error(message, null, { timeOut: 10000 });
  };

  /**
   * Displays an error to the user without a timeout (user has to dismiss it) and logs to the console
   * @param {string} resourceKey
   * @param {string} [debugMessage] Message to be logged for debugging / internal purposes
   * @param {*} [errorObject]
   */
  self.errorWithoutTimeout = function (resourceKey, debugMessage, errorObject) {

    var message = resourceHelper.getString(resourceKey);
    var errorDetails = createDetails(resourceKey, message, debugMessage, errorObject);

    if (config.showErrorDebugMessage && debugMessage) {
      message += ' ' + debugMessage;
    }

    console.error(errorDetails);
    toastr.error(message, null, { timeOut: null });
  };

  /**
   * Logs a warning
   * @param {string} resourceKey
   * @param {string} [debugMessage] Message to be logged for debugging / internal purposes
   * @param {Object} [errorObject]
   */
  self.warning = function (resourceKey, debugMessage, errorObject) {

    var message = resourceHelper.getString(resourceKey);
    var warningDetails = createDetails(resourceKey, message, debugMessage, errorObject);

    if (config.showErrorDebugMessage && debugMessage) {
      message += ' ' + debugMessage;
    }

    console.warn(warningDetails);
    toastr.warning(message, null, { timeOut: 10000 });
  };

  /**
   * Logs a debug message to the console for a technical / developer audience
   * @param {string} debugMessage
   * @param {*} [optionalParams]
   */
  self.debug = function (debugMessage, optionalParams) {

    if (config.loggingSettings.handleDebug) {

      if (optionalParams) {
        console.debug(debugMessage, optionalParams);
      } else {
        console.debug(debugMessage);
      }
    }
  };

  self.success = function (resourceKey) {

    var message = resourceHelper.getString(resourceKey);
    toastr.success(message);
  };

  self.info = function (resourceKey) {

    var message = resourceHelper.getString(resourceKey);
    toastr.info(message);
  };

  /**
   * Logs an error to the console and displays an untranslated friendly message to the user. This method
   * must only be used for errors that occur during SPA startup when it's not possible to determine the
   * language for the user and therefore a translated friendly message can't be displayed.
   *
   * NOTE: Only use from within the "Loaders" scripts where resource data has yet to be loaded.
   *
   * @param {string} friendlyMessage
   * @param {string|null} debugMessage
   * @param {error} [errorObject]
   */
  self.logAndThrowUntranslatedError = function (friendlyMessage, debugMessage, errorObject) {

    errorObject = errorObject || {};

    // If the error has already been handled then nothing to do here
    if (errorObject.errorHasBeenLogged) {
      return;
    }

    var resourceKey = 'None: Untranslated error';
    var errorDetails = createDetails(resourceKey, friendlyMessage, debugMessage, errorObject);
    var message = friendlyMessage;

    if (config.showErrorDebugMessage && debugMessage) {
      message += ' ' + debugMessage;
    }

    console.error(errorDetails);
    toastr.error(message, null, { timeOut: null }); // Don't automatically hide the toastr, user must close.

    errorObject.errorHasBeenLogged = true;
    throw errorObject;
  };

  self.logForbiddenAccess = function () {

    var resourceKey = 'ForbiddenAccessErrorMessage';
    var message = 'You have attempted to access content or perform an action that you are currently forbidden access to.';

    if (resourceHelper.hasResourceData()) {
      message = resourceHelper.getString(resourceKey);
    }

    var warningDetails = createDetails(resourceKey, message);

    console.warn(warningDetails);
    toastr.warning(message, null, { timeOut: 0, preventDuplicates: true });
  };

  self.logTimeout = function () {

    var resourceKey = 'AjaxTimeOutErrorMessage';
    var message = 'The server took too long to respond with data and a timeout occurred. Please try again later.';

    if (resourceHelper.hasResourceData()) {
      message = resourceHelper.getString(resourceKey);
    }

    var warningDetails = createDetails(resourceKey, message);

    console.warn(warningDetails);
    toastr.warning(message, null, { timeOut: 10000, preventDuplicates: true });
  };

}

function createDetails(resourceKey, friendlyMessage, debugMessage, errorObject) {

  return {
    resourceKey: resourceKey,
    friendlyMessage: friendlyMessage,
    debugMessage: debugMessage,
    errorObject: errorObject
  };
}

export default new Logger();
